<script setup lang="ts">
import { subject } from '@casl/ability';
import integrations from '@respell/integrations';
import SpellRunDrawer from '~/components/modals/SpellRunDrawer.vue';
import TemplateModal from '~/components/modals/TemplateModal.vue';
import IssuesDropdown from '~/components/spell/IssuesDropdown.vue';
import PublishButton from '~/components/spell/PublishButton.vue';
import ShareButton from '~/components/spell/ShareButton.vue';
import SpellTabs from '~/components/spell/SpellTabs.vue';
import VersionHistory from '~/components/spell/VersionHistory.vue';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();

const spellStore = useSpellsStore();
const canvasStore = useCanvasStore();
const tutorialStore = useTutorialStore();

const { spell, trigger } = storeToRefs(spellStore);
const {
  isSaving,
  trigger: draftTrigger,
  triggerDefinition,
  errors,
} = storeToRefs(canvasStore);
const { routeName, inEditor } = useRouteName();
const slideover = useSlideover();
const modal = useModal();

const backPath = computed(() =>
  inEditor.value || routeName.value === 'spell.settings'
    ? '/spell/' + spell.value?.id + (trigger.value ? '/history' : '/run')
    : undefined,
);

const isEnabled = computed({
  get() {
    return trigger.value?.isEnabled;
  },
  set(value) {
    spellStore.toggleTrigger(value);
  },
});

const disableTest = computed(
  () => !!draftTrigger.value && !triggerDefinition.value?.example,
);

const localSpellName = ref(spell.value?.name || '');

watchDebounced(
  localSpellName,
  async (newName) => {
    if (
      newName != 'New spell' &&
      newName != undefined &&
      newName != '' &&
      spellStore.spell?.id !== undefined
    )
      await spellStore.updateSpell(spell.value?.id, { name: newName });
  },
  { debounce: 150, maxWait: 300 },
);

const inputWidth = computed(() => {
  const length = localSpellName.value.length;
  return `${length * 7 + 60}px`; // Adjust the multiplier and base value as needed
});

const handleEdit = async () => {
  if (routeName.value === 'preview') {
    modal.open(TemplateModal);
  } else {
    await navigateTo(`/spell/${spell.value?.id}/edit`, { replace: true });
  }
};

watch(inEditor, async (value) => {
  if (!value && localSpellName.value !== 'New spell') {
    await spellStore.updateSpell(spell.value?.id, {
      name: localSpellName.value,
    });
  } else {
    localSpellName.value = spell.value?.name || '';
  }
});

const backButtonLabel = computed(() => {
  return routeName.value === 'preview'
    ? 'Exit Preview'
    : routeName.value === 'editor'
      ? 'Exit'
      : 'Back';
});

function openTest() {
  tutorialStore.hasTestedSpell = true;
  slideover.open(SpellRunDrawer);
}
</script>
<template>
  <div
    class="px-8 py-1 sticky top-0 left-0 w-full grid border-b bg-white border-gray-200 z-50"
    :class="inEditor ? 'grid-cols-2' : 'grid-cols-[1fr,1fr,1fr]'"
  >
    <!-- LEFT ACTIONS -->
    <div class="flex gap-5 justify-self-start">
      <!-- EXIT -->
      <AppBackButton
        :key="backPath"
        :to="backPath"
        :text="backButtonLabel"
        replace
        class="text-gray-600"
      />
      <!-- SPELL LABEL -->
      <div class="flex flex-row justify-center">
        <AppSpellIcon :spell="spell" class="mr-s" size="m" />
        <UInput
          v-if="inEditor"
          v-model="localSpellName"
          variant="none"
          :padded="false"
          :ui="{
            variant: { none: 'subtitle text-base' },
            trailing: { padding: { sm: 'pe-5' } },
          }"
          :style="{ width: inputWidth }"
        >
          <template #trailing>
            <UIcon
              v-show="isSaving"
              name="i-ph-arrow-clockwise"
              class="text-primary-500 text-lg animate-spin"
            />
          </template>
        </UInput>
        <p v-else class="subtitle truncate">
          {{ spell?.name }}
        </p>
      </div>
      <!-- REVISION -->
      <VersionHistory v-if="inEditor" :spell-id="spell?.id" />
      <UTooltip
        v-else-if="trigger"
        :text="`Connect your ${integrations[trigger.service].name} account to enable this spell.`"
        :prevent="!!trigger.integration"
      >
        <div class="flex flex-row items-center gap-1.5 ml-s">
          <UIcon
            name="i-ph-circle-fill"
            class="transition-colors duration-300"
            :class="
              isEnabled ? 'text-green-500 animate-pulse' : 'text-gray-300'
            "
          />
          <p class="body dimmed">{{ isEnabled ? 'Live' : 'Off' }}</p>
          <UToggle
            v-model="isEnabled"
            :disabled="
              !trigger.integration || !can('edit', subject('Spell', spell))
            "
            color="primary"
            size="lg"
            class="ml-2"
          />
        </div>
        <UButton
          v-if="trigger.service === 'slack' && isEnabled"
          icon="i-ph-arrow-clockwise-bold"
          size="xl"
          class="ml-2"
          color="white"
          variant="solid"
          label="Force Check"
          :trailing="false"
          :ui="{ icon: { size: { xl: 'h-5 w-5' } } }"
          @click="spellStore.checkTrigger()"
        />
      </UTooltip>
    </div>

    <SpellTabs
      v-if="!inEditor"
      class="place-self-center"
      :class="{ invisible: routeName === 'preview' || !!trigger }"
    />

    <!-- RIGHT ACTIONS -->
    <div id="spell-topbar-actions" class="flex justify-self-end gap-3">
      <IssuesDropdown v-if="inEditor && Object.values(errors).flat()?.length" />
      <div v-if="inEditor" class="contents">
        <!-- SPELLCAST -->
        <!-- <GenerateButton v-if="userStore.user?.isAdmin" /> -->
        <!-- TEST -->
        <UTooltip
          :prevent="!disableTest"
          text="Sorry, this trigger cannot be tested"
        >
          <UButton
            icon="i-ph-play-bold"
            size="xl"
            color="white"
            variant="solid"
            label="Test"
            :disabled="disableTest"
            :trailing="false"
            :ui="{ icon: { size: { xl: 'h-5 w-5' } } }"
            @click="openTest"
          />
        </UTooltip>
        <!-- PUBLISH -->
        <PublishButton />
      </div>
      <div v-else class="contents">
        <ShareButton class="hidden lg:block" />
        <!-- EDIT -->
        <!-- <UButton
          v-if="routeName === 'preview'"
          icon="i-ph-copy"
          size="xl"
          color="primary"
          variant="solid"
          label="Use Template"
          :trailing="false"
          @click="() => spellStore.duplicateSpell(spell.id)"
        /> -->
        <UButton
          v-if="can('edit', subject('Spell', spell))"
          icon="i-ph-note-pencil"
          size="xl"
          color="primary"
          variant="solid"
          :label="`Edit${routeName === 'preview' ? ' Template' : ''}`"
          :trailing="false"
          @click="handleEdit"
        />
      </div>
      <!-- MORE -->
      <SpellActions v-if="!inEditor" :spell="spell">
        <template #button>
          <UButton
            icon="i-ph-dots-three-outline-vertical-fill"
            size="xl"
            square
            color="white"
            variant="solid"
            :ui="{ icon: { size: { xl: 'h-5 w-5' } } }"
          />
        </template>
      </SpellActions>
    </div>
  </div>
</template>
