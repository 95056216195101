<script setup lang="ts">
import type { Graph } from '@respell/database';

const props = defineProps<{
  spellId: string;
}>();
const spellStore = useSpellsStore();
const canvasStore = useCanvasStore();

const { restoreGraph } = canvasStore;

const { data: versions, refresh } = await useAsyncCache(
  `graphs/${props.spellId}`,
  async () => spellStore.loadVersions(props.spellId as string),
  { watch: [props], lazy: true },
);

const versionOptions = computed(() => {
  return versions.value?.length
    ? versions.value.map((graph: Graph) => [
        {
          label:
            graph.type === 'draft' ? `Draft (${graph.version})` : graph.version,
          class:
            graph.type === 'draft' ? 'bg-gray-200 pointer-events-none' : '',
          click: async () => {
            if (graph.type !== 'draft') {
              await restoreGraph(graph.id);
              await refresh();
            }
          },
        },
      ])
    : [];
});
</script>
<template>
  <UDropdown :items="versionOptions" :popper="{ placement: 'bottom-start' }">
    <div class="flex flex-row items-center space-x-2 is-clickable">
      <UIcon
        name="i-ph-clock-counter-clockwise"
        class="text-3xl text-gray-600"
      />
      <p class="body dimmed">Draft</p>
      <UIcon name="i-ph-caret-down-bold" class="text-xl text-gray-600" />
    </div>
  </UDropdown>
</template>
